import $ from 'jquery';
import './index.scss';
$.fn.showNavItemMenuHeader = function(){
	let selector = $(this).find('.menu-item-has-children');

    selector.on('click', function(e){
		$('.menu-item-has-children').not(this).removeClass('active');
        $(this).toggleClass('active');
        e.preventDefault;
    });
}
$.fn.showNavItemCardMenuHeader = function(){
	let selector1 = $(this).find('.menu-item-has-children.nav-item-card-menu-0');
	let selector2 = $(this).find('.menu-item-has-children.nav-item-card-menu-1');

    selector1.on('click', function(){
		$(this).toggleClass('active');
		if (!$(this).hasClass('active')) {
			$('.nav-item-card-menu-0').show();			
		} 
		else {
			$('.nav-item-card-menu-0').not('.active').hide();
		}
		//e.preventDefault();
    });

	selector2.on('click', function(){
		selector1.removeClass('active').show();
        $(this).toggleClass('active');
		if (!$(this).hasClass('active')) {
			$('.nav-item-card-menu-1').show();	
			$('.nav-link-card-menu-0').show()
		} 
		else {
			$('.nav-item-card-menu-1').not('.active').hide();
			$('.nav-link-card-menu-0').hide()
		}
       	//e.preventDefault;
    });

}
$.fn.showNavItemMenuWorld = function(){
	let selector = '.menu--wrapper';

    $(selector).on('click', function(e){
        $(this).toggleClass('active');
        e.preventDefault;
    });
}

$.fn.webfactoryNavResponsive = function(){
	let $container = $(this);
	let $menu = $(this).find('.menu--root');
	let originalHtml = $menu.html();
	let childWidth = function($item){
		let sum= 0;
		$item.children().each(function(){
			sum += $(this).width()+4;
		})

		return sum;
	}
	let update = function(){
		$menu.html(originalHtml);
		let children = [];

		let index = 0;
		$menu.children().each(function(){
			index++;
			children[$menu.children().length - index] = $(this);
		})
		let label = $container.find('.menu--wrapper').data('moreLabel') || 'More';
		let $plus = $('<li>').addClass('nav-item nav-item-level-0 menu-item-plus menu-item menu-item-has-children').html(`
			<a class="nav-link nav-link-level-0">${label}</a>
			<ul class="has-white-background-color row row-cols-md-2 gx-2 has-primary-color  nav nav-level-0"></ul></div>`);
		let $sub = $plus.children('ul');
		if ($container.width()<=childWidth($menu)){
			$menu.append($plus);
			children.map(($x,i)=>{
				if ($container.width()<=childWidth($menu)+100){
					$x.appendTo($sub);
				}
			})
			$sub.find('ul').remove();
			$sub.find('.nav-link-level-0').addClass('nav-link-level-1').removeClass('nav-link-level-0');
			$sub.find('.nav-item-level-0').addClass('nav-item-level-1').removeClass('nav-item-level-0');
			$sub.find('.menu-item-has-children').removeClass('menu-item-has-children');
			
		}
	}
	$(window).on('resize', update)
	update();
}
$.fn.webfactoryNavResponsiveMenu = function(){
	let $container = $(this);
	let update = function(){
		if($(window).width() <= 1464){
			$container.closest('.container').css("max-width", "100%");
			$container.closest('.container').css("font-size", "0.8rem");

		}else{
			$container.closest('.container').css("max-width", "1460px");
			$container.closest('.container').css("font-size", "1.05rem");

		}
		if($(window).width() <= 1260){
			$container.closest('header').removeClass('d-md-block').addClass('d-md-none');
			$('#header-toolbar-mobile').closest('header').removeClass('d-md-none').addClass('d-md-block');
			$('#header-toolbar-mobile').find('[data-template="menu"]').removeClass('d-md-none').addClass('d-md-block');
		}else{
			$container.closest('header').removeClass('d-md-none').addClass('d-md-block');
			$('#header-toolbar-mobile').closest('header').removeClass('d-md-block').addClass('d-md-none');


		}
		/*let $plus = $('<li>').addClass('nav-item nav-item-level-0 menu-item-plus menu-item menu-item-has-children nav-item-card-menu-0').html(`
		$menu.html(originalHtml);
		let children = [];

		let index = 0;
		$menu.children().each(function(){
			index++;
			children[$menu.children().length - index] = $(this);
		})
		let label = $container.find('.menu--wrapper').data('moreLabel') || 'More';
			<a class="nav-link nav-link-level-0 nav-link-card-menu-0">${label}</a>
			<div class='card-menu-level-0 d-flex'>
				<div class="card-menu-prefix">
					<div class="card-header">
					<h6 class="card-title"></h6>
					</div>
					<div class="card-body">
					<div class="card-description"></div>      
					</div>
					<div class="card-footer">
					<div class="is-style-button-white">
						<a class="wp-block-button__link card-button"></a>
					</div>
					</div>
				</div>
			<ul class="has-white-background-color row row-cols-md-2 gx-2 has-primary-color  nav nav-level-0 nav-card-menu-level-0"></ul></div>`);
		let $sub = $plus.children('div').children('ul');
		if ($container.width()<=childWidth($menu)){
			$menu.append($plus);
			children.map(($x,i)=>{
				if ($container.width()<=childWidth($menu)+100){
					$x.appendTo($sub);
				}
			})

			$sub.find('.nav-link-level-0').addClass('nav-link-level-1').removeClass('nav-link-level-0');
			$sub.find('.nav-item-level-0').addClass('nav-item-level-1').removeClass('nav-item-level-0');

			$sub.find('.nav-link-level-0.nav-link-card-menu-0').addClass('nav-link-level-1 nav-link-card-menu-1').removeClass('nav-link-level-0 nav-link-card-menu-0');
			$sub.find('.nav-item-level-0.nav-item-card-menu-0').addClass('nav-item-level-1 nav-item-card-menu-1').removeClass('nav-item-level-0 nav-item-card-menu-0');
			$sub.find('.nav-level-0.nav-card-menu-level-0 .card-menu-level-0')
			.toggleClass('nav-level-0 nav-level-1')
			.toggleClass('nav-card-menu-level-0 nav-card-menu-level-1')
			.toggleClass('card-menu-level-0 card-menu-level-1');
			//$sub.find('.menu-item-has-children').removeClass('menu-item-has-children');
			
		}*/
	}
	$(window).on('resize', update)
	update();
}

$(function(){
	$('[data-template="alten_world_mobile"], [data-template="alten_header_mobile"], .is-style-menu-expanded, .is-style-menu-world, .is-style-menu-subfooter, .is-style-menu-corporate').each(function() {
		$(this).find('.card-menu-prefix').remove();
	});
	$('.wp-block-webfactory-menu.is-style-menu-country, .wp-block-webfactory-menu.is-style-menu-corporate').each(function(){
		if ($(this).find('.menu--wrapper').hasClass('card-menu--wrapper')){
			$(this).webfactoryNavResponsiveMenu();
		}else{
			$(this).webfactoryNavResponsive();}		
	})

	$('[data-template="alten_header_mobile"]').each(function(){
		if($(this).find('.nav-item-level-0 ').hasClass('nav-item-card-menu-0')){
			$(this).showNavItemCardMenuHeader();
		}else{
			$(this).showNavItemMenuHeader();
		}
	})

    $('[data-template="alten_world_mobile"]').each(function(){
		$(this).showNavItemMenuWorld();
	})

	$('.is-style-menu-expanded').each(function(){
		$(this).showNavItemMenuHeader();
	})

	$('#header-toolbar-2').each(function(){	
		$('.nav-item-card-menu-0.menu-item-has-children').hover(
			function() {
			$(this).addClass('bar-menu');
			$(this).prevAll().addClass('bar-menu');
			},
			function() {
				$(this).removeClass('bar-menu');
				$(this).prevAll().removeClass('bar-menu');

			}
	);
})
	$('.wp-block-webfactory-menu.is-style-menu-country').each(function(){	
		$('.nav-link-card-menu-0, .nav-link-card-menu-1, .menu-item-has-children.nav-link-card-menu-1').hover(
			function() {
				let parentLi = $(this).parents('.nav-item-card-menu-0').first();
				let title = $(this).find('.nav-link-text').text();
				let dataDescription = $(this).data('description');
				let dataBtn = $(this).data('btn');
				let link = $(this).attr('href');

				if (dataDescription == '' && dataBtn == '') {
					title = parentLi.find('.nav-link-text-card-0').text();
					dataDescription = parentLi.find('.nav-link-card-menu-0').data('description');
					dataBtn = parentLi.find('.nav-link-card-menu-0').data('btn');
					link = parentLi.find('.nav-link-card-menu-0').attr('href');
				}

				let card = $('.card-menu-prefix');
				card.find('.card-title').text(title);
				card.find('.card-description').html(dataDescription.substring(0, 250));
				card.find('.card-button').text(dataBtn);
				card.find('.card-button').attr('href', link);
			}	
			
		);

	})

	$('.card-menu--wrapper').each(function(){
		$('.menu-item-has-children.nav-item-card-menu-1').hover(
			function() {
				$('.card-menu-level-0').css("backgroundColor", "white");
			}, 
			function(){
				$('.card-menu-level-0').css("backgroundColor", "#F5F8F8");

			}
		);
	})
})